.container {
    right: 4px;
    position: fixed;
    top: 64px;
    width: 200px;
    z-index: 9999;

    > div {
        margin: 0 0 4px 0 !important;
        padding: 8px !important;

        > div {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            font-size: 12px !important;
            margin: 0 !important;
            line-height: 1;

            > p {
                font-size: 12px !important;
                margin: 0 0 4px 0 !important;
                line-height: 1;
                flex: 1;
            }
        }

        .status {
            background-color: #b0b0b0;
            display: inline-block;
            border-radius: 100%;
            height: 6px;
            margin-right: 4px;
            width: 6px;
            position: relative;
            top: 2px;

            &.pending {
                background-color: rgb(241, 191, 70);
            }
            &.inProgress {
                background-color: rgb(45, 185, 76);
            }
            &.error {
                background-color: #dd0000;
            }
        }
    }
}
