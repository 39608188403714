.timeline {
    position: relative;
}

.timelineLine {
    background: rgba(0, 0, 0, .1);
    border-radius: 5px;
    height: 100%;
    position: absolute;
    left: 3px;
    width: 5px;
}

.timelineItem {
    margin-left: 30px !important;

    &:before {
        background: #f4f4f4;
        border: solid 3px rgb(170, 170, 170);
        border-radius: 100%;
        content: '';
        display: block;
        height: 17px;
        left: -34px;
        position: absolute;
        top: 7px;
        transition:
            background 150ms linear,
            border 150ms linear;
        width: 17px;
    }

    &:hover:before {
        background: var(--primary-color);
        border-color: var(--primary-color);
    }
}

.timelineHeader {
    align-items: center;
    display: flex;
    color: rgba(0, 0, 0, .4);
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    margin-bottom: 10px;
}

.timelineMeta {
    flex: 1;
    margin-left: 10px;

    b {
        display: block;
        color: #000;
        font-size: 16px;
    }
}

.smallTable {
    tbody tr td {
        font-size: .75rem;
        padding: .5rem 1rem !important;
    }
}
