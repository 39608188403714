.container {
    height: 60px;
    position: relative;
    z-index: 1001;
}

.innerContainer {
    align-items: center;
    background: var(--secondary-color);
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;
}

.logo {
    display: block;
    width: 120px;
}

.navigation {
    flex: 1;
    padding: 0 30px;

    a {
        color: var(--secondary-opposite-color) !important;

        &:hover {
            color: var(--primary-color) !important;
        }
    }
}

.profile {
    align-items: center;
    display: flex;

    > div:first-child {
        color: var(--secondary-opposite-color) !important;
        margin-right: 15px;
    }

    a {
        color: var(--secondary-opposite-color) !important;
    }
}

.profileName {
    color: var(--secondary-opposite-color) !important;

    &:hover {
        color: var(--primary-color) !important;
    }
}
