.headerCell {
    position: sticky;
    z-index: 3;
    top: 60px;
}

.headerCellFilter {
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px !important;
}

.headerCellFilterItem {
    padding: 5px;
}
