@import './config.scss';

body {
    background-color: #f4f4f4 !important;
    margin: 0;
    padding: 0 0 100px 0 !important;

    --primary-color: #dd0000;
    --secondary-color: #ffffff;
    --secondary-opposite-color: #333333;
}

.ui.primary.button {
    background: var(--primary-color) !important;
    transition: background 150ms linear;
}

.ui.primary.button:hover {
    background: #ccc !important;
}

.formError {
    color: #a83a38;
    font-size: 11px;
    margin: 0 0 5px 0 !important;
    position: relative;
    font-style: italic;
    top: -8px;
}

a.primary {
    color: var(--primary-color) !important;
    // transition: color 150ms linear;

    &:hover {
        color: #bbb !important;
    }
}

a.primary.button {
    color: #fff !important;
}

a.default {
    color: rgba(0,0,0,.87) !important;
    transition: color 150ms linear;

    // &:hover {
    //     color: #bbb !important;
    // }
}

.pageContainer {
    max-width: 1140px;
    padding: 0 15px;
    margin: 50px auto;
    
    &--fluid {
        max-width: 90%;
    }
}

.toastify {
    padding:15px 45px 15px 15px;
    position:fixed;
    border-radius:3px;
    min-width: 200px;
    opacity: 0;
    z-index:9999;

    &.toastify-bottom-right {
        bottom: 70px;
        right: 15px;
    }

    &.toastify-top-right {
        top: 15px;
        right: 15px;
    }

    &.toastify-top-left {
        top: 15px;
        left: 15px;
    }

    &.toastify-bottom-left {
        bottom: 15px;
        left: 15px;
    }

    &.toastify-success {
        background: #fcfff5;
        color: #4a7c4a;
        border:1px solid #a3c293;
    }

    &.toastify-warning {
        background: #f39c12;
        color:white;
        border:1px solid darken(#f39c12, 5%);
    }

    &.toastify-default {
        background: #333;
        color:white;
        border:1px solid darken(#333, 5%);
    }

    &.toastify-error {
        background: #fff6f6;
        color:#a83a38;
        border:1px solid #e0b4b4;
    }

    &.toastify-info {
        background: #3498db;
        color:white;
        border:1px solid darken(#3498db, 5%);
    }

    span {
        display:block;
    }

    .toastify-title {
        font-weight:bold;
    }

    .toastify-cancel-icon {
        position:absolute;
        top:14px;
        font-size:18px;
        right:10px;
        cursor:pointer;
    }
}

.topBar .active {
    background: rgba(0, 0, 0, .1) !important;

    a {
        text-decoration: underline !important;
    }
}

.topBar .menu.transition.visible {
    left: auto !important;
    right: -30px !important;
}

.dashboard .ui.mini.statistic > .value {
    font-size: 18px !important;
}

abbr {
    border-bottom: dotted 1px #333;
}


.fwarn {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4001;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    padding: 5px;

    &--dev {
        background: #4a7c4a;
    }

    &--staging {
        background: #f39c12;
    }
}
