.stats {
    justify-content: center;
    display: flex;
    height: 90px;
}

.stat {
    flex-basis: 16.666666667%;
    padding: 0 10px;
    text-align: center;
    height: 100%;
}

.label {
    font-size: 12px !important;
    color: rgba(0, 0, 0, .5) !important;
    margin-bottom: 5px !important;
}

.segment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.input {
    width: 80px;
    margin: 0 auto;

    input {
        text-align: center !important;
    }
}

.value {
    font-size: 18px !important;
}

.filter {
    height: 40px;
    position: sticky;
    top: 60px;
    z-index: 100;
    width: 400px;
}

.fixedFilter {
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    top: 60px;
    z-index: 1000;

    > div {
        max-width: 1140px;
        padding: 10px 15px;
        width: 100%;
    }
}
