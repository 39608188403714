.editLogo {
    border: solid 1px #efefef;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 30px;

    img { 
        display: block;
        width: 100%;
    }
}

.editStartImage {
    margin-bottom: 20px;

    img { 
        display: block;
        width: 100%;
    }
}
