.container {
    display: block;
    text-align: center;
}

.base {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    // max-width: 120px;
    // min-width: 77px;
    height: 40px;
    width: 120px;

    img {
        width: 100%;
    }
}
