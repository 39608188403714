.container {
    align-items: center;
    background-position: 50%;
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    opacity: 0;
    transition: opacity 250ms linear;
    width: 100vw;
}

.form {
    max-width: 400px;
    width: 90%;
}

.buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    
    button {
        margin-left: 10px !important;
    }
}
