.container {
    background: rgba(255, 255, 255, 1);
    z-index: 100;
    bottom: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 0 30px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.push {
    height: 60px;
}
